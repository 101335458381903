export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: true,
  apiUrl: 'https://ecmsfhcapi.centurycodes.ng/api',
  courtTitle: 'FEDERAL HIGH COURT OF NIGERIA',
  courtTitleSentence: 'Federal High Court of Nigeria',
  stateTitle: 'FCT, Abuja',
  stateTitleSentence: 'Federal Capital Territory Abuja',
  currentDivision: 'Abuja Judicial Division',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsfhcapi.centurycodes.ng/api',
  BASE_URL_II: 'https://ecmsfhcapi.centurycodes.ng/api',
  API_URL: '/',
  currentState: 'Federal High Court of Nigeria',
  PORTAL_URL: 'https://ecmsfhcportal.centurycodes.ng',
  court_bg_path: 'assets/img/court-bg.jpg',
  court_logo_path: 'assets/img/ecms-light-logo.png',
  paystack_public_key: 'pk_test_0e8e87eb3fdf39c788648eefeb54063a3c293a3d',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  remitta_mode: 'test',
  interswitch_mechant_code: 'VNA',
  interswitch_pay_item: '103',
  interswitch_mode: 'TEST',
  currentCourtInfoEmail: "info@fhc.gov.ng"
};
